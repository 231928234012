import React, { useContext, useState, useCallback } from "react";
import { Stack, Text, Label, Separator, DefaultButton, IconButton } from '@fluentui/react';
import { useLocation, useNavigate } from "react-router-dom";
import { TeamsFxContext } from "../Context";
import EditUserModal from "../modals/UserModal";
import BackdropLoader from "../modals/BackdropLoader"

function UserDetails() {
    const location = useLocation();
    const navigate = useNavigate();
    const { apiClient } = useContext(TeamsFxContext);
    const { user, currentPage, searchTerm, items, currentuser, appliedFilters, dropdownValues } = location.state || {};
    const [isSaving, setIsSaving] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [localItems, setLocalItems] = useState(items || []);
    const [localUser, setLocalUser] = useState(user);
    const [localDropdownValues, setLocalDropdownValues] = useState(dropdownValues);

    const getUserRoleName = (roleId) => {
        if (!localDropdownValues?.userroles?.length) return roleId;
        const role = localDropdownValues.userroles?.find(r => r.userrole_id === roleId);
        return role ? role.userrole_name : roleId;
    };

    const getPlantName = (plantId) => {
        if (!localDropdownValues?.plants?.length) return plantId;
        const plant = localDropdownValues.plants.find(p => p.plant_id === plantId);
        return plant ? plant.plant_cd : plantId;
    };

    const getAreaName = (areaId) => {
        if (!localDropdownValues?.areas?.length) return areaId;
        const area = localDropdownValues.areas.find(a => a.area_id === areaId);
        return area ? area.area : areaId;
    };

    const getDepartmentName = (deptId) => {
        if (!localDropdownValues?.departments?.length) return deptId;
        const dept = localDropdownValues.departments.find(d => d.department_id === deptId);
        return dept ? dept.department : deptId;
    };

    const getSubAreaName = (subareaId) => {
        if (!localDropdownValues?.subareas?.length) return subareaId;
        const subarea = localDropdownValues.subareas.find(s => s.subarea_id === subareaId);
        return subarea ? subarea.sub_area : subareaId;
    };

    const handleDisplayValue = (values, mappingFunction) => {
        if (!values) return '';
        if (Array.isArray(values)) {
            return values
                .filter(value => value !== null && value !== undefined)
                .map(value => mappingFunction(value))
                .filter(value => value)
                .join(', ');
        }
        return mappingFunction(values);
    };

    const getShiftName = (shiftId) => {
        if (!shiftId) return '';
        const shiftMap = {
            1: '1',
            2: '2',
            3: '3'
        };
        return shiftMap[shiftId] || '';
    };

    React.useEffect(() => {
        const fetchDropdownValues = async () => {
            if (!localDropdownValues) {
                try {
                    const response = await apiClient.get('getDefaultValues');
                    setLocalDropdownValues(response.data.data);
                } catch (err) {
                    console.error("Error fetching dropdown values:", err);
                }
            }
        };
        fetchDropdownValues();
    }, [apiClient, localDropdownValues]);

    const handleBackClick = useCallback(() => {
        navigate('/user-profile', {
            state: {
                currentPage,
                searchTerm,
                items: localItems,
                appliedFilters,
                dropdownValues: localDropdownValues
            }
        });
    }, [navigate, currentPage, searchTerm, localItems, appliedFilters, localDropdownValues]);

    const handleEditClick = useCallback(() => setIsModalOpen(true), []);

    const handleSaveEdit = async (saveData) => {
        setIsSaving(true);
        if (!apiClient) {   
            console.error("API client is not available");
            return;
        }

        try {
            if (saveData.deletedIds.length > 0) {
                await Promise.all(
                    saveData.deletedIds.map(id => apiClient.delete(`userProfile?id=${id}`))
                );
            }

            const results = await Promise.all(
                saveData.updates.map(async (rowData) => {
                    if (rowData.action === 'update') {
                        // Update existing row
                        const { action, ...updateData } = rowData;
                        await apiClient.put(`userProfile?id=${rowData.id}`, updateData);
                        return rowData;
                    } else {
                        // Create new row
                        const { action, ...createData } = rowData;
                        console.log('Creating new row:', [createData]);
                        const response = await apiClient.post("userProfile", [createData]);
                        return { ...rowData, id: response.data.id };
                    }
                })
            );

            console.log('Save results:', results);

            // Update local state with new data
            const newItems = localItems.map(item => {
                if (item.allIds && item.allIds.some(id => saveData.updates.some(update => update.id === id))) {
                    const relatedRows = results.filter(row =>
                        row.gminId === item.gmin &&
                        row.email === item.user_email
                    );

                    return {
                        ...item,
                        userrole_id: relatedRows.map(row => row.role),
                        plant_id: relatedRows.map(row => row.plant),
                        area_id: relatedRows.map(row => row.area),
                        department_id: relatedRows.map(row => row.department),
                        shift: relatedRows.map(row => row.shift),
                        subarea_id: relatedRows.map(row => row.subarea),
                        allIds: [
                            ...relatedRows.map(row => row.id)
                        ].filter(id => !saveData.deletedIds.includes(id))
                    };
                }
                return item;
            });

            setLocalItems(newItems);
            const updatedUser = newItems.find(item =>
                item.allIds ? item.allIds.includes(user.id) : item.id === user.id
            );
            setLocalUser(updatedUser);

            console.log('State updated successfully');
        } catch (err) {
            console.error("Error updating user:", err);
        } finally {
            setIsSaving(false);
            setIsModalOpen(false);
        }
    };

    const handleDeleteClick = async () => {
        if (!apiClient) {
            console.error("API client is not available");
            return;
        }

        try {
            const idsToDelete = localUser.allIds || [localUser.id];
            await Promise.all(idsToDelete.map(id =>
                apiClient.delete(`userProfile?id=${id}`)
            ));

            // Filter out all the deleted items
            const newItems = localItems.filter(item => {
                if (item.allIds) {
                    return !item.allIds.some(id => idsToDelete.includes(id));
                }
                return !idsToDelete.includes(item.id);
            });

            setLocalItems(newItems);

            // Navigate to previous page if the last item is deleted
            const newPage = (newItems.length % 10 === 0) && currentPage > 1 ? currentPage - 1 : currentPage;

            navigate('/user-profile', {
                state: {
                    currentPage: newPage,
                    searchTerm,
                    items: newItems,
                    appliedFilters,
                    dropdownValues: localDropdownValues
                }
            });

        } catch (err) {
            console.error("Error deleting user:", err);
        }
    };

    // Return early only after hooks are called
    if (!localUser) return <div>No data available</div>;

    return (
        
        <Stack tokens={{ childrenGap: 20, padding: 20 }} styles={{ root: { backgroundColor: '#FFFFFF', position: 'relative' } }}>
           {isSaving && <BackdropLoader />}
            <Stack horizontal verticalAlign="center" horizontalAlign="space-between">
                <Stack horizontal verticalAlign="center">
                    <IconButton
                        iconProps={{ iconName: 'Back' }}
                        onClick={handleBackClick}
                        title="Back"
                        ariaLabel="Back"
                        style={styles.backButton}
                    />
                    <Text style={styles.textCommon}>Back</Text>
                </Stack>


                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <DefaultButton text="Edit" size="medium" onClick={handleEditClick} style={styles.button} />
                    <DefaultButton text="Delete" size="medium" onClick={handleDeleteClick} style={styles.button} />
                </Stack>
            </Stack>

            <Separator />


            <Stack tokens={{ childrenGap: 10 }}>
                <Stack verticalAlign="center">
                    <Label style={styles.labelCommon}>Name</Label>
                    <Text style={styles.textCommon}>{localUser.name}</Text>
                </Stack>

                <Stack verticalAlign="center">
                    <Label style={styles.labelCommon}>Email</Label>
                    <Text style={styles.textCommon}>{localUser.user_email}</Text>
                </Stack>

                <Stack verticalAlign="center">
                    <Label style={styles.labelCommon}>Role</Label>
                    <Text style={styles.textCommon}>
                        {handleDisplayValue(localUser.userrole_id, getUserRoleName)}
                    </Text>
                </Stack>

                <Stack verticalAlign="center">
                    <Label style={styles.labelCommon}>Plant</Label>
                    <Text style={styles.textCommon}>
                        {handleDisplayValue(localUser.plant_id, getPlantName)}
                    </Text>
                </Stack>

                <Stack verticalAlign="center">
                    <Label style={styles.labelCommon}>Area</Label>
                    <Text style={styles.textCommon}>
                        {handleDisplayValue(localUser.area_id, getAreaName)}
                    </Text>
                </Stack>

                <Stack verticalAlign="center">
                    <Label style={styles.labelCommon}>Department</Label>
                    <Text style={styles.textCommon}>
                        {handleDisplayValue(localUser.department_id, getDepartmentName)}
                    </Text>
                </Stack>

                <Stack verticalAlign="center">
                    <Label style={styles.labelCommon}>Shift</Label>
                    <Text style={styles.textCommon}>
                        {handleDisplayValue(localUser.shift, getShiftName)}
                    </Text>
                </Stack>

                <Stack verticalAlign="center">
                    <Label style={styles.labelCommon}>Sub Area</Label>
                    <Text style={styles.textCommon}>
                        {handleDisplayValue(localUser.subarea_id, getSubAreaName)}
                    </Text>
                </Stack>
            </Stack>

            <EditUserModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSave={handleSaveEdit}
                userData={localUser}
                user={currentuser}
                mode="edit"
                dropdownValues={localDropdownValues}
            />

            <Separator />

            <Separator />
        </Stack>
    );
}

const styles = {
    container: {
        childrenGap: 20,
        padding: 20,
        backgroundColor: '#FFFFFF'
    },
    textCommon: {
        fontFamily: "Roboto",
        fontWeight: 400,
        lineHeight: 1.5,
        textAlign: "left",
        color: "#1F1F1F",
        fontSize: 14
    },
    labelCommon: {
        fontFamily: "Roboto",
        fontWeight: "bold",
        lineHeight: 1.5,
        textAlign: "left",
        color: "#959593",
        fontSize: 12
    },
    button: {
        border: "1px solid #0072CE"
    },
    backButton: {
        marginRight: 10,
        fontFamily: "Fabric",
        fontSize: 16,
        fontWeight: 400,
        lineHeight: 16,
        textAlign: "center",
        color: "#0072CE"
    }
};


export default UserDetails;
